import React from "react"

import Layout from "../components/layout/layout"
import SEO from "../components/seo"

const NotFoundPage = () => (
    <Layout>
        <SEO title="Raw Engineering" />
        <div className='container text-center' style={{padding:'50px 0', display:'flex', alignItems:'center', justifyContent:'center', minHeight:'calc(61vh - 90px)'}}>
            <h3>Sorry, Something went wrong.</h3>
        </div>
    </Layout>
)

export default NotFoundPage
